import {
  DdosProtectionTypes,
  FWaaSRuleSet,
  Location,
  LocationType,
  Money,
  MrcNrc,
  PortTypes,
  ServiceComponentVLANType,
} from '@/lib/definitions/api.types'

export enum IpTransitTabSteps {
  Location = 1,
  Ports = 2,
  IpTransit = 3,
  Summary = 4,
}

export type IpTransitStep = keyof typeof IpTransitTabSteps

export enum FlexEthernetTabSteps {
  Endpoints = 1,
  Summary = 2,
}

export type FlexEthernetStep = keyof typeof FlexEthernetTabSteps

export enum IpAccessTabSteps {
  Location = 1,
  Ports = 2,
  IpAccess = 3,
  Summary = 4,
}

export type IpAccessStep = keyof typeof IpAccessTabSteps

export interface City {
  locations: Location[]
  name: string
  country: string
  activeLocations: number
}

export interface PortSpeed {
  id: number
  speedInMbps: number
  speed: number
  unit: string
  type: string
  name: string
  isLag?: boolean
}

export interface PortSpeedOption extends PortSpeed, MrcNrc {
  attribute_id?: number
}

export interface Prefix {
  id: number
  addresses: number
  isRecommended?: boolean
  note?: string
}

export interface PrefixOption extends Prefix, MrcNrc {}

export interface CommittedDataRateOption {
  speedInMbps: number
  speed: number
  unit: 'Mbps' | 'Gbps'
  nrc?: Money
  mrc?: Money
}

export interface FieldTypes {
  key: string
  label: string
  type?: any
  defaultValue?: Function
}

export interface WizardTabItem {
  id: string
  label: string
  headline?: string
}

export interface ContractTerm {
  label: string
  label_short: string
  period: number
}

export interface BandwidthOptions {
  [id: number]: number[]
}

export interface FlexEthernetEndpoint {
  selectedLocation: string
  selectedLocationId?: number
  selectedLocationSpeed: number
  selectedPortSpeed?: PortSpeedOption
  selectedPortType?: string
  selectedPortName: PortTypes | null
  selectedServiceSpeed: number | null
  selectedLocationType: LocationType
  vlanId: number | null | undefined
  vlanType: ServiceComponentVLANType | undefined
  existingPortId?: number
  lag?: LAGProperties
  flexConnectKey?: string
}

export interface ExistingPort {
  id: number
  name: string
  locationName: string
  portType: string
  isLag: boolean
  lagCount: number
  portSpeed: PortSpeed
  serviceSid?: string
  sco?: string
  virtualNetworkName?: string
}

export interface ExistingPortItem extends ExistingPort {
  location: Location
}

export interface PortDetail {
  key: string
  label: string
  value?: string | number
  isNrc?: boolean
}

export interface LAGProperties {
  memberCount: number
  name: string
}

export interface GeoCoordinates {
  lat: number
  lng: number
}

export interface GeoCircle {
  center: GeoCoordinates
  radius: number
}

export interface GeoRectangle {
  southWest: GeoCoordinates
  northEast: GeoCoordinates
}

export interface FirewallPrices {
  amount: string
  currency: string
  display: string
  speed: number
  speedInMbps: number
  unit: string
}

export type ConfigureFirewallType = {
  name: string
  term: string
  rule_set: FWaaSRuleSet | ''
  bandwidth: string
  reference: string
}

export enum FirewallCreationSteps {
  ConnectElan = 1,
  ChooseLocation = 2,
  ConfigureFirewall = 3,
  Checkout = 4,
}

export enum DDoSPrefixType {
  IPv4 = 'IPv4 /24',
  IPv6 = 'IPv6 /48',
}

export enum DDoSPrefixSize {
  FLEXDETECT = 'flexdetect',
  PLUS = 'plus',
  PREMIUM = 'premium',
  ENTERPRISE = 'enterprise',
}

export type DDoSPrefix = {
  count: number
  type: DDoSPrefixType
  size: DDoSPrefixSize
  term: string
}

export interface DDoSProtectionPricingMrc extends DDoSPrefix {
  cost: number
}

export interface DDoSProtectionPricing {
  [tier: string]: DDoSProtectionPricingMrc
}

export type IpServices = 'ip-access' | 'ip-transit'

export type AmountType = {
  totalAmount: number | null
  bandwidthAmount: number | null
}

export type DDoSTier = {
  tier: number
  value: DdosProtectionTypes
  title: string
  features: TierFeature[]
  price: DDoSProtectionPricing
}

export type TierFeature = {
  name: string
  value: string
}
