import Button from '@/components/Button'
import GenericInput from '@/components/GenericInput'
import { Select } from '@/components/SelectInputs'
import { useApp } from '@/lib/provider/AppProvider'
import { useMemo, useState } from 'react'
import WizardContractTerm from './partials/WizardContractTerm'
import { ContractTerm } from './ServiceWizard.types'
import { ProductIcon } from '@/lib/misc/ProductIcons'
import LocationDropdown from '@/components/LocationDropdown'
import { Location } from '@/lib/definitions/api.types'
import { DynamicProduct as DynamicProductSchema } from '@/lib/definitions/api.types'
import Toggle from '@/components/Toggle'
import { ApiService } from '@/lib/services/ApiService'
import { useNavigate } from 'react-router-dom'
import { m } from 'framer-motion'
import { Notify } from '@/lib/misc/Notify'

interface DynamicProductProps {
  productId: string
}

const apiService = new ApiService()

const DynamicProduct = ({ productId }: DynamicProductProps) => {
  const navigate = useNavigate()
  const [appState] = useApp()
  const [formData, setFormData] = useState<any>({})
  const [fieldsWithCustomLocations, setFieldsWithCustomLocations] = useState<
    string[]
  >([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleChange = (field: string, value: any) => {
    setFormData({ ...formData, [field]: value })
    console.log('formData', formData)
  }

  const product = useMemo(
    () =>
      appState.appInfo.products.find(
        (product: DynamicProductSchema) => product.product_key === productId
      ),
    [appState.appInfo.products, productId]
  )

  const submit = async () => {
    setIsLoading(true)
    try {
      await apiService.sendDynamicProductRequest({
        dynamic_product: productId,
        payload: formData,
      })
      setIsSubmitted(true)
    } catch (error) {
      console.error('error', error)
      Notify.error('Failed to send request. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  const onToggleCustomLocation = (field: string, checked: boolean) => {
    if (checked) {
      setFieldsWithCustomLocations([...fieldsWithCustomLocations, field])
    } else {
      setFieldsWithCustomLocations(
        fieldsWithCustomLocations.filter((f) => f !== field)
      )
    }
    setFormData({ ...formData, [field]: '' })
  }

  const isFormValid = useMemo(() => {
    return Object.keys(product.fields).every((field) => {
      return formData[field] !== undefined && formData[field] !== ''
    })
  }, [formData, product.fields])

  const sendAnotherRequest = () => {
    setIsSubmitted(false)
    setFormData({})
    setFieldsWithCustomLocations([])
  }

  if (isSubmitted) {
    return (
      <m.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col justify-center items-center min-h-screen max-w-xl mx-auto"
      >
        <div className="flex flex-col items-center gap-4">
          <div className="text-brand-1">
            <ProductIcon productId={product.product_key} size={60} />
          </div>
          <h1 className="text-3xl font-bold text-brand-1">
            {product.title} Request Sent
          </h1>
          <p className="text-gray-500 mt-4 max-w-md text-center">
            Your request has been sent to the {product.title} team. They will
            get back to you as soon as possible.
          </p>
          <div className="flex flex-row gap-2 mt-4">
            <Button style="secondary" onClick={() => navigate('/')}>
              Back to Home
            </Button>
            <Button style="primary" onClick={() => sendAnotherRequest()}>
              Send Another Request
            </Button>
          </div>
        </div>
      </m.div>
    )
  }

  return (
    <div className="flex flex-col gap-4 mx-auto min-h-screen max-w-3xl mt-16">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <div>
            <ProductIcon
              productId={product.icon || product.id}
              size={32}
              className="text-brand-1"
            />
          </div>
          <h1 className="text-4xl font-bold text-brand-1 uppercase">
            {product.title}
          </h1>
        </div>
        <div className="text-sm text-gray-500">{product.description}</div>
      </div>
      <div className="flex flex-col gap-8 mx-auto w-full mb-24">
        {Object.keys(product.fields)
          .sort(
            (a, b) => product.fields[a].position - product.fields[b].position
          )
          .map((field: any, index: number) => {
            const fieldData = product.fields[field]
            return (
              <div
                key={`field-${index}`}
                className="w-full flex flex-col gap-1"
              >
                <div className="flex justify-between">
                  <div className="text-heading-byline tracking-tightest text-brand-1">
                    {fieldData.label}
                  </div>
                  {fieldData.type === 'location' && (
                    <div className="text-sm text-gray-500 flex items-center gap-2">
                      <span>Use custom location</span>
                      <Toggle
                        checked={fieldsWithCustomLocations.includes(field)}
                        onChange={(checked: boolean) => {
                          onToggleCustomLocation(field, checked)
                        }}
                        disabled={isLoading}
                      />
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-500">
                  {fieldData.description}
                </div>

                <div>
                  {[
                    'input',
                    'number',
                    'email',
                    'string',
                    'date',
                    'datetime',
                    'time',
                    'password',
                    'search',
                    'text',
                    'url',
                    'tel',
                  ].includes(fieldData.type) && (
                    <GenericInput
                      type={
                        ['input', 'string'].includes(fieldData.type)
                          ? 'text'
                          : fieldData.type
                      }
                      className="w-full"
                      name={field}
                      label={fieldData.label}
                      value={formData[field] ?? ''}
                      disabled={isLoading}
                      onChange={(e) => {
                        handleChange(field, e.target.value)
                      }}
                    />
                  )}
                  {fieldData.type === 'select' && (
                    <Select
                      name={field}
                      label={fieldData.label}
                      options={fieldData.options.map((option: any) => ({
                        value: option,
                        label: option,
                      }))}
                      value={formData[field] ?? ''}
                      disabled={isLoading}
                      setValue={(value: string) => {
                        handleChange(field, value)
                      }}
                    />
                  )}
                  {fieldData.type === 'location' &&
                    !fieldsWithCustomLocations.includes(field) && (
                      <LocationDropdown
                        selectedLocation={formData[field] ?? ''}
                        onChange={(location: Location) => {
                          handleChange(field, location.id)
                        }}
                      />
                    )}
                  {fieldData.type === 'location' &&
                    fieldsWithCustomLocations.includes(field) && (
                      <GenericInput
                        type="text"
                        name={field}
                        label={fieldData.label}
                        value={formData[field] ?? ''}
                        disabled={isLoading}
                        onChange={(e) => {
                          handleChange(field, e.target.value)
                        }}
                      />
                    )}

                  {fieldData.type === 'contract_term' && (
                    <WizardContractTerm
                      setDefaultValue={false}
                      showTitle={false}
                      inline={false}
                      contract={formData[field]}
                      minContractTerm={12}
                      setContractTerm={(value: ContractTerm) => {
                        handleChange(field, value.period)
                      }}
                      // minContractTerm={fieldData.min_contract_term}
                    />
                  )}
                </div>
              </div>
            )
          })}

        <div className="flex justify-end">
          <Button
            onClick={submit}
            style="primary"
            disabled={isLoading || !isFormValid}
          >
            Send Request
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DynamicProduct
