import UserGroupIcon from '@icons/user-group'
import DocumentTextIcon from '@icons/document-text'
import PhoneIcon from '@icons/phone'
import AtSymbolIcon from '@icons/at-symbol'

export const Help = () => {
  return (
    <div className="w-full sm:w-[600px] p-6 relative z-[30] rounded-xl shadow-2xl shadow-neutral-60 text-accent-10 bg-brand-1 bg-gradient-to-tl from-neutral-90 to-80% to-auxiliary-2/40">
      <div className="flex flex-row relative z-10 space-x-4">
        <div className="w-2/5">
          <div className="w-full flex items-center justify-center">
            <UserGroupIcon className="size-24 text-white/20" />
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-heading-2 text-white">Support</h3>

          <div className="leading-5">
            Found a bug or have a suggestion for how we can improve the portal?
            Let us know and we’ll get to work on it.
          </div>
        </div>
      </div>

      <div className="mt-8 relative">
        <div className="mb-2 ml-0">
          Business Hours are Monday to Friday 9 AM to 5 PM CET/CEST.
        </div>
        <div className="flex justify-between p-3 bg-white/10 relative z-10 rounded-lg">
          <div className="flex items-center justify-center hover:text-white hover:scale-105 transition">
            <span className="w-5 text-white/80">
              <PhoneIcon className="size-4" />
            </span>
            <a href="tel:+4930577037417">+49 30 5770 374 17</a>
          </div>

          <div className="flex items-center justify-center hover:text-white hover:scale-105 transition">
            <span className="w-5 text-white/80">
              <AtSymbolIcon className="size-4" />
            </span>
            <a href="mailto:support@inter.link">support@inter.link</a>
          </div>
        </div>
      </div>

      <div className="mt-8 relative">
        <div className="mb-2 ml-0">
          For <strong>24/7</strong> Support please contact:
        </div>

        <div className="flex justify-between p-3 bg-white/10 relative z-10 rounded-lg">
          <div className="flex items-center justify-center hover:text-white hover:scale-105 transition">
            <span className="w-5 text-white/80">
              <PhoneIcon className="size-4" />
            </span>
            <a href="tel:+4930577037417">+49 30 5770 374 14</a>
          </div>

          <div className="flex items-center justify-center hover:text-white hover:scale-105 transition">
            <span className="w-5 text-white/80">
              <DocumentTextIcon className="size-4" />
            </span>
            <a
              href="https://inter.link/wp-content/uploads/2024/10/271023-INTERLINK-Pricelist-and-Escalation-Matrix.pdf"
              target="_blank"
            >
              Escalation Matrix
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
