import { createContext, useContext, useEffect, useReducer } from 'react'
import { useQuery } from 'react-query'

import SilentFullPageErrorFallback from '@/components/SilentFullPageErrorFallback'
import { AuthService } from '@/lib/services/AuthService'
import { UserAndCompany } from '../definitions/api.types'

const auth = new AuthService()

export type AuthState = {
  user: UserAndCompany
  active: boolean
}

export const SET_USER = 'setUser'
export const UPDATE_USER = 'updateUser'
export const REFRESH_USER = 'refreshUser'

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        active: true,
      }

    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }

    case REFRESH_USER:
      return {
        ...state,
        refetchTrigger: state.refetchTrigger + 1,
      }

    default:
      return state
  }
}

export const initialState = {
  active: false,
  refetchTrigger: 0,
}

export type Action =
  | { type: typeof SET_USER; payload: UserAndCompany }
  | { type: typeof UPDATE_USER; payload: Partial<UserAndCompany> }
  | { type: typeof REFRESH_USER }

interface AuthContextType {
  state: AuthState
  dispatch: React.Dispatch<Action>
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

AuthContext.displayName = 'AuthContext'

function AuthProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchUser = async () => {
    const user = await auth.getUser()
    if (!user) return null

    dispatch({ type: SET_USER, payload: user.data })

    return user
  }

  const { isLoading, isSuccess, isError, error, status } = useQuery(
    ['getUser'],
    fetchUser,
    {
      cacheTime: 0,
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        const { response } = error

        if (response?.status === 401) {
          auth.logout()
          auth.clear()
          setTimeout(() => (document.location = '/login'), 20)
        }
      },
    }
  )

  useEffect(() => {
    if (state.refetchTrigger > 0) {
      fetchUser()
    }
  }, [state.refetchTrigger])

  if (isLoading) {
    return <div />
  }

  if (isError) {
    return <SilentFullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={[state, dispatch]} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

/**
 * @returns [AuthState, React.Dispatch<Action>]
 */
function useAuth(): any {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
