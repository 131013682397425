import { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import ArrowUpRightIcon from '@icons/arrow-up-right'
import { useTenant } from '@/lib/provider/TenantProvider'

const CustomerBanner: FC = () => {
  const { companyName, tenantName, tenantCid } = useTenant()

  return (
    <div className="flex items-center justify-between px-6 py-4 bg-auxiliary-1 text-base font-normal leading-4 text-black md:text-accent-60 md:px-8">
      <div className="hidden md:block">
        You’re viewing the portal of <b>{tenantName}</b>
        {tenantCid && <span className="text-xs ml-1">({tenantCid})</span>}
      </div>
      <Link
        to="/customers"
        className="w-full flex items-center justify-between cursor-pointer md:justify-start md:w-auto"
      >
        <div className="hidden mr-1.5 md:block">
          Back to {companyName} portal
        </div>
        <div className="block mr-1.5 md:hidden">{tenantName}</div>
        <ArrowUpRightIcon className="size-4" />
      </Link>
    </div>
  )
}

export default memo(CustomerBanner)
