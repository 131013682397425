import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { m, LazyMotion, domAnimation } from 'framer-motion'
import { useMemo } from 'react'

import { useTenant } from '@/lib/provider/TenantProvider'
import { useApp } from '@/lib/provider/AppProvider'
import ExclamationTriangleIcon from '@icons/exclamation-triangle'
import { classNames } from '@/lib/misc/Utils'

import Navigation from '@/layouts/partials/Navigation'
import CustomerBanner from '@/components/CustomerBanner'
import TenantNavigation from '@/layouts/partials/TenantNavigation'
import Footer from '@/layouts/partials/Footer'

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.9,
  },
}

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
}

const MemberLayout = () => {
  const [appState] = useApp()
  const location = useLocation()
  const { customerId } = useTenant()

  // Add this function to check if the current route is a sub-route of tickets
  const shouldNotAnimate = useMemo(() => {
    return (
      location.pathname.startsWith('/tickets/') &&
      location.pathname !== '/tickets'
    )
  }, [location.pathname])

  const isServiceWizard = useMemo(() => {
    return location.pathname.startsWith('/services/')
  }, [location.pathname])

  return (
    <LazyMotion features={domAnimation} strict>
      <div
        className="flex flex-col min-h-full w-full bg-brand-1"
        id="member-layout"
        data-cy="member-layout"
      >
        {customerId ? (
          <>
            <CustomerBanner />
            <TenantNavigation />
          </>
        ) : (
          <div>
            <Navigation />
          </div>
        )}

        <div className="flex-1 flex bg-neutral-10 rounded-xl mx-2 mb-2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.6)]">
          <main className="min-h-full overflow-y-auto flex-1">
            {appState.appInfo &&
              typeof appState.appInfo.email_verified === 'boolean' &&
              appState.appInfo.email_verified === false && (
                <div
                  className={classNames(
                    'mx-auto mt-8',
                    isServiceWizard ? 'max-w-7xl px-6' : 'max-w-6xl'
                  )}
                >
                  <div
                    className={classNames(
                      'border-status-warning border-2 bg-white p-4 rounded-xl border-warning-30 shadow-neutral-60'
                    )}
                  >
                    <div className="flex items-center gap-3">
                      <ExclamationTriangleIcon className="w-6 h-6 text-status-warning" />
                      <span className="text-neutral-60">
                        Please verify your email address to access all features.
                      </span>
                    </div>
                  </div>
                </div>
              )}

            {shouldNotAnimate ? (
              <Outlet />
            ) : (
              <m.div
                key={location.pathname}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Outlet />
              </m.div>
            )}
          </main>
        </div>

        <Footer className="px-6" />
      </div>
    </LazyMotion>
  )
}

export default MemberLayout
