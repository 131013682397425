import React, { useState, useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'
import { ApiService } from '@/lib/services/ApiService'
import { OptionsType } from '@/components/GenericOption'
import { Location } from '@/lib/definitions/api.types'
import LoadingSpinner from './LoadingSpinner'
import { useOnClickOutside } from '@/lib/hooks/useOnClickOutside'
import ChevronUpDownIcon from '@icons/chevron-up-down'
import CheckIcon from '@icons/check'
import { classNames } from '@/lib/misc/Utils'
import { CircleFlag } from 'react-circle-flags'

interface FilterableDropdownProps {
  selectedLocation?: string
  onChange?: (location: Location, label: string) => void
}

const apiService = new ApiService()

const FilterableDropdown: React.FC<FilterableDropdownProps> = ({
  selectedLocation,
  onChange,
}) => {
  const [open, setOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>(selectedLocation || '')
  const [options, setOptions] = useState<OptionsType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<OptionsType | null>(null)
  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(-1)
  const listItemRefs = useRef<(HTMLLIElement | null)[]>([])
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => setOpen(false))

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value: string) => {
        setSearchQuery(value)
      }, 250),
    []
  )

  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel()
    }
  }, [debouncedSetSearchQuery])

  useEffect(() => {
    if (!open) return

    async function fetchLocations() {
      try {
        setIsLoading(true)
        const res = await apiService.searchLocations(searchQuery)
        const locOptions: OptionsType[] = (res.data.results || []).map(
          (loc: any) => ({
            label: `${loc.location.name} - ${loc.location.city.name}, ${loc.location.city.country.name} - ${loc.location.description}`,
            value: loc.location.id,
            location: loc.location,
          })
        )
        setOptions(locOptions)
      } catch (error) {
        console.error('Error fetching locations', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchLocations()
  }, [searchQuery, open])

  const handleOptionSelect = (option: OptionsType) => {
    setSelectedOption(option)
    if (onChange) {
      onChange(option.location, option.label)
    }
    setOpen(false)
    setSearchQuery('')
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!open) return
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setActiveOptionIndex((prev) => (prev < options.length - 1 ? prev + 1 : 0))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setActiveOptionIndex((prev) => (prev > 0 ? prev - 1 : options.length - 1))
    } else if (e.key === 'Enter') {
      if (activeOptionIndex >= 0 && activeOptionIndex < options.length) {
        e.preventDefault()
        handleOptionSelect(options[activeOptionIndex])
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      setOpen(false)
      setActiveOptionIndex(-1)
    }
  }

  useEffect(() => {
    if (open) {
      setActiveOptionIndex(-1)
    }
  }, [open])

  useEffect(() => {
    if (activeOptionIndex >= 0 && listItemRefs.current[activeOptionIndex]) {
      listItemRefs.current[activeOptionIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [activeOptionIndex])

  return (
    <div ref={ref}>
      <div className="relative">
        <button
          type="button"
          onClick={() => setOpen((prev) => !prev)}
          className={classNames(
            'relative w-full bg-white border border-neutral-20 rounded-md pl-3 pr-10 py-2 h-[50px] text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-base',
            selectedOption && 'text-auxiliary-3'
          )}
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-labelledby="listbox-label"
        >
          <span className="w-full inline-flex truncate">
            <span
              className={classNames(
                'truncate',
                selectedOption ? 'text-auxiliary-3' : 'text-neutral-50'
              )}
            >
              {selectedOption ? selectedOption.label : 'Select a location'}
            </span>
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon className="size-5 text-neutral-50" />
          </span>
        </button>
        {open && (
          <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 min-h-32">
            <div className="absolute top-0 left-0 right-0 bg-white px-3 py-2 h-16 rounded-t-md z-10">
              <input
                type="text"
                onChange={(e) => debouncedSetSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search..."
                className="w-full border border-neutral-20 rounded-md py-2 px-2 focus:outline-none focus:ring-1 focus:ring-accent-40 focus:border-accent-40"
                autoFocus
              />
              {isLoading && (
                <span className="absolute right-4 top-1/2 transform -translate-y-1/2">
                  <LoadingSpinner className="size-8" />
                </span>
              )}
            </div>
            <ul
              className="pt-16 max-h-80 overflow-auto text-base focus:outline-none sm:text-sm"
              role="listbox"
              aria-labelledby="listbox-label"
            >
              {options.map((option, index) => (
                <li
                  key={option.value}
                  ref={(el) => (listItemRefs.current[index] = el)}
                  onClick={() => handleOptionSelect(option)}
                  onMouseEnter={() => setActiveOptionIndex(index)}
                  className={`cursor-default select-none relative py-2 pl-3 pr-9 ${index === activeOptionIndex || (selectedOption && option.value === selectedOption.value) ? 'bg-accent-40 text-white' : 'text-auxiliary-3'}`}
                  role="option"
                >
                  <div className="flex">
                    <span className="truncate">
                      <span className="flex flex-row gap-2">
                        <CircleFlag
                          countryCode={option.location.city.country.short_name.toLocaleLowerCase()}
                          height="14"
                          width="14"
                          alt={option.location.city.country.short_name}
                          title={option.location.city.country.short_name}
                        />
                        <span
                          className={classNames(
                            'font-bold w-28',
                            (selectedOption &&
                              option.value === selectedOption.value) ||
                              index === activeOptionIndex
                              ? 'text-white'
                              : 'text-neutral-60'
                          )}
                        >
                          {option.location.name}
                        </span>
                        <div className="truncate">
                          {option.location.city.name},{' '}
                          {option.location.city.country.name},{' '}
                          {option.location.description}
                        </div>
                      </span>
                    </span>
                  </div>
                  {selectedOption && option.value === selectedOption.value && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <CheckIcon className="size-5 text-white" />
                    </span>
                  )}
                </li>
              ))}
              {!isLoading && options.length === 0 && (
                <li className="text-gray-500 px-3 py-2">No results found.</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default FilterableDropdown
