import { classNames } from '@/lib/misc/Utils'

interface ToggleProps {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

const Toggle = ({ checked, onChange, disabled }: ToggleProps) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          if (disabled) return
          onChange(e.target.checked)
        }}
        disabled={disabled}
        className={classNames('sr-only peer')}
      />
      <div
        className={classNames(
          "w-11 h-6 bg-neutral-30 peer-focus:outline-none dark:peer-focus:ring-brand-1 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brand-1",
          disabled && 'cursor-not-allowed'
        )}
      ></div>
    </label>
  )
}

export default Toggle
