import RouterIcon from '@fluent-icons/router-24-regular'
import ConnectorIcon from '@fluent-icons/connector-24-regular'
import ShieldIcon from '@fluent-icons/shield-24-regular'
import GlobeShieldRegular from '@fluent-icons/globe-shield-24-regular'
import GlobeIcon from '@fluent-icons/globe-24-regular'
import CloudFlowIcon from '@fluent-icons/cloud-flow-24-regular'
import GroupIcon from '@fluent-icons/group-24-regular'
import PlugConnectedCheckmarkIcon from '@fluent-icons/plug-connected-checkmark-20-regular'
import ChannelShareIcon from '@fluent-icons/channel-share-28-filled'

export const ProductIcons = {
  ip_transit: RouterIcon,
  ip_access: RouterIcon,
  flex_ethernet: ConnectorIcon,
  ddos_protection: GlobeShieldRegular,
  network_functions_firewall: ShieldIcon,
  generic: GlobeIcon,
  cloud_connect: CloudFlowIcon,
  group: GroupIcon,
  plug_connected_checkmark: PlugConnectedCheckmarkIcon,
  flex_peer: ChannelShareIcon,
}

type ProductIconProps = {
  productId: string
  className?: string
  size?: number
}

export const ProductIcon = ({
  productId,
  className,
  size,
}: ProductIconProps) => {
  if (!ProductIcons[productId as keyof typeof ProductIcons]) {
    productId = 'generic'
  }

  const Icon = ProductIcons[productId as keyof typeof ProductIcons]

  return <Icon className={className} fontSize={size} />
}
