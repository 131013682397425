import { ReactElement, ReactNode, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { createPortal } from 'react-dom'

import { classNames } from '@/lib/misc/Utils'
import ArrowTopRightOnSquare from '@icons/arrow-top-right-on-square'

export interface LinkProps {
  id?: string
  label: string | ReactElement
  to?: string
  isSeparated?: boolean
  onClick?: () => void
  cy?: string
  icon?: ReactElement
  external?: boolean
}

interface DropdownMenuProps {
  links: LinkProps[]
  children: ReactNode
  className?: string
  alignment?: 'left' | 'right'
  width?: number | string
  id?: string
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [toggled, setToggle] = useState(false)
  const [menuPosition, setMenuPosition] = useState<{
    top: number
    left: number
    width: number
  } | null>(null)
  const [shouldRender, setShouldRender] = useState(toggled)
  const [animate, setAnimate] = useState(false)

  const {
    children,
    links,
    className = 'flex items-center',
    alignment = 'right',
  } = props

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !triggerRef.current?.contains(event.target as Node) &&
        !menuRef.current?.contains(event.target as Node)
      ) {
        setToggle(false)
      }
    }

    if (toggled) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [toggled])

  useEffect(() => {
    if (toggled && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect()
      const menuWidth =
        typeof props.width === 'number' ? props.width : rect.width
      setMenuPosition({
        top: rect.bottom + window.scrollY + 8,
        left:
          alignment === 'left'
            ? rect.left + window.scrollX
            : rect.right + window.scrollX - menuWidth,
        width: rect.width,
      })
    }
  }, [toggled, alignment, props.width])

  useEffect(() => {
    if (toggled) {
      setShouldRender(true)
    } else {
      const timeout = setTimeout(() => {
        setShouldRender(false)
        setMenuPosition(null)
      }, 200)
      return () => clearTimeout(timeout)
    }
  }, [toggled])

  useEffect(() => {
    if (toggled) {
      const timer = setTimeout(() => {
        setAnimate(true)
      }, 10)
      return () => clearTimeout(timer)
    } else {
      setAnimate(false)
    }
  }, [toggled])

  return (
    <div className={className}>
      <div className="relative">
        <button
          ref={triggerRef}
          type="button"
          className="flex text-sm rounded-full"
          id={props.id}
          data-cy={props.id}
          aria-expanded={toggled}
          aria-haspopup="true"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            setToggle(!toggled)
          }}
        >
          <span className="sr-only">Open menu</span>
          {children}
        </button>

        {shouldRender &&
          menuPosition &&
          createPortal(
            <div
              ref={menuRef}
              className={classNames(
                'fixed transition-all duration-200 ease-out transform',
                'rounded-lg shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[250] text-left',
                animate
                  ? 'scale-100 opacity-100'
                  : 'scale-95 opacity-0 pointer-events-none'
              )}
              style={{
                position: 'absolute',
                top: menuPosition.top,
                left: menuPosition.left,
                width: props.width || menuPosition.width,
                zIndex: 250,
              }}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabIndex={-1}
            >
              {links?.map((link, index) => {
                const itemProps = {
                  className: classNames(
                    'block text-sm text-text-base',
                    link.isSeparated && 'border-t border-gray-100 mt-1 pt-1'
                  ),
                  role: 'menuitem',
                  tabIndex: -1,
                  cy: link.cy || `menu-${link.id}`,
                }

                const label = (
                  <span className="block px-4 py-2 hover:bg-gray-50 transition-colors cursor-pointer flex items-center space-x-2 justify-between group">
                    <div className="flex items-center space-x-2">
                      {link.icon && (
                        <div className="size-4 text-gray-500 group-hover:text-gray-700">
                          {link.icon}
                        </div>
                      )}
                      <span className="group-hover:text-gray-900">
                        {link.label}
                      </span>
                    </div>
                    {link.external && (
                      <div className="opacity-50 size-3 ml-auto group-hover:opacity-70">
                        <ArrowTopRightOnSquare className="h-full w-full" />
                      </div>
                    )}
                  </span>
                )

                return link.to ? (
                  <Link
                    {...itemProps}
                    key={index}
                    to={link.to}
                    onClick={() => setToggle(false)}
                    data-cy={itemProps.cy}
                    target={link.external ? '_blank' : undefined}
                    rel={link.external ? 'noopener noreferrer' : undefined}
                  >
                    {label}
                  </Link>
                ) : (
                  <div
                    {...itemProps}
                    key={index}
                    data-cy={itemProps.cy}
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      setToggle(false)
                      link.onClick?.()
                    }}
                  >
                    {label}
                  </div>
                )
              })}
            </div>,
            document.body
          )}
      </div>
    </div>
  )
}

export default DropdownMenu
