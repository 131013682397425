import LoadingSpinner from '@/components/LoadingSpinner'
import {
  LAG,
  Port,
  Service,
  ServiceComponent,
} from '@/lib/definitions/api.types'
import { ProductIdentifiers } from '@/lib/definitions/types'
import { useApp } from '@/lib/provider/AppProvider'
import { ServiceService } from '@/lib/services/ServiceService'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DynamicProduct from '../ServiceWizard/DynamicProduct'

const serviceService = new ServiceService()

const ServiceDetailsPageViaServiceId = () => {
  const navigate = useNavigate()
  const [appState] = useApp()
  const { serviceId = '' } = useParams()
  const [isError, setIsError] = useState(false)
  const [dynamicProductId, setDynamicProductId] = useState('')

  const redirectToServiceComponentDetails = (
    serviceComponent: ServiceComponent | LAG | Port
  ) => {
    if (serviceComponent.response_type === 'LAG') {
      return navigate(`/services/lag/${serviceComponent.id}`)
    }
    if (serviceComponent.response_type === 'Port') {
      return navigate(`/services/port/${serviceComponent.id}`)
    }
  }

  const redirectToServiceDetails = (service: Service) => {
    if (!service) return
    switch (service.product.pid) {
      case ProductIdentifiers.IP_TRANSIT:
        return navigate(`/services/ip-transit/${service.id}`)
      case ProductIdentifiers.IP_ACCESS:
        return navigate(`/services/ip-access/${service.id}`)
      case ProductIdentifiers.FLEX_ETHERNET_ENDPOINT:
      case ProductIdentifiers.LAYER2:
        return navigate(`/services/flex-ethernet/${service.id}`)
      case ProductIdentifiers.DDOS_PROTECTION:
        return navigate(`/services/ddos-protection/${service.id}`)
      case ProductIdentifiers.EXTENSION:
        return navigate(`/services/extensions/${service.id}`)
      default:
        console.warn('Unknown service type:', service.response_type)
        setIsError(true)
    }
  }

  useEffect(() => {
    const serviceIdCleaned = serviceId?.toLowerCase()
    if (/^sco\d+$/.test(serviceIdCleaned)) {
      serviceService.getServiceComponent(serviceId).then((serviceComponent) => {
        redirectToServiceComponentDetails(serviceComponent.data)
      })
      return
    } else if (/^sid\d+$/.test(serviceIdCleaned)) {
      serviceService.getService(serviceId).then((service) => {
        redirectToServiceDetails(service.data)
      })
      return
    }

    if (!serviceId || !appState.appInfo?.products) return
    const serviceIds =
      appState.appInfo.products.map((product: any) =>
        product.product_key.toLowerCase()
      ) || []
    const serviceIdWithoutHyphens = serviceIdCleaned.replace(/-/g, '_')
    if (serviceIds.includes(serviceIdWithoutHyphens)) {
      setDynamicProductId(serviceIdWithoutHyphens)
    } else {
      setIsError(true)
    }
  }, [serviceId, appState.appInfo?.products])

  if (dynamicProductId) {
    return <DynamicProduct productId={dynamicProductId} />
  }

  return isError ? (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center gap-4">
        <LoadingSpinner className="size-40" spin={false} />
        <div className="text-accent-60 text-lg font-bold">
          Service not found
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center h-screen">
      <LoadingSpinner className="size-18" />
    </div>
  )
}

export default ServiceDetailsPageViaServiceId
