import axios, { AxiosPromise } from 'axios'

import {
  Country,
  IPTransitCreate,
  IPTransitOptions,
  Invoice,
  IPTransit,
  DynamicProductRequestCreate,
} from '@/lib/definitions/api.types'
import {
  apiUrl,
  encodeQueryParams,
  getAxiosRequestConfigWithHeaders,
} from '@/lib/misc/Utils'

export class ApiService {
  #baseUrl = '/api/v1/'

  url(path: string) {
    return apiUrl(this.#baseUrl + path)
  }

  searchLocations(search?: string) {
    return axios.get(
      this.url('location/search/simple'),
      getAxiosRequestConfigWithHeaders({}, { params: { query: search } })
    )
  }

  getInvoices(): AxiosPromise<Invoice[]> {
    return axios.get(this.url('invoice/'), getAxiosRequestConfigWithHeaders())
  }

  getIpTransitWizardOptions(): AxiosPromise<IPTransitOptions> {
    return axios.get(
      this.url('ip-transit/wizard'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  queryAsnNumber(asn: number) {
    return axios.get(
      this.url('ip-transit/wizard/peeringdb'),
      getAxiosRequestConfigWithHeaders(
        {},
        {
          params: {
            asn,
          },
        }
      )
    )
  }

  getCountries(): AxiosPromise<Country[]> {
    return axios.get(this.url('country/'), getAxiosRequestConfigWithHeaders())
  }

  getCities(city: string, country?: string): AxiosPromise<string[]> {
    return axios.get(
      this.url(`country/cities?city=${city}&country=${country}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getSentrySettings(): AxiosPromise<any> {
    return axios.get(apiUrl(`/sentry-environment/`))
  }

  createIpTransitOrder(data: IPTransitCreate): AxiosPromise<IPTransit> {
    return axios.post(
      this.url('ip-transit/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  getProductPrice(params: {
    product_id: string
    location: string
    term: number
  }): AxiosPromise<any> {
    //TODO use proper type
    return axios.get(
      apiUrl(`/api/v1/product-price/?${encodeQueryParams(params)}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  requestPriceSummary(data: any): AxiosPromise<any> {
    //TODO use proper types
    return axios.post(
      apiUrl('/api/v1/product-price/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  sendDynamicProductRequest(data: DynamicProductRequestCreate): AxiosPromise<any> {
    return axios.post(
      apiUrl('/api/v1/dynamic-product/request'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }
}
