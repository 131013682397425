import { MouseEvent, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

import DropdownMenu, { LinkProps } from '@/layouts/partials/DropdownMenu'
import { classNames } from '@/lib/misc/Utils'
import ArrowTopRightOnSquare from '@icons/arrow-top-right-on-square'
import { DropdownContent } from '@/layouts/partials/DropdownContent'

export interface NavigationItemInterface {
  id?: string
  icon?: ReactElement
  to?: string
  children?: NavigationItemInterface[]
  onClick?: Function | undefined
  title?: string
  links?: LinkProps[]
  isHidden?: boolean
  external?: boolean
  content?: ReactElement
  isActive?: boolean
  alignment?: 'left' | 'right'
  highlight?: boolean
}

export interface NavigationGroupInterface {
  className?: string
  items: NavigationItemInterface[]
}

const NavigationGroupItem = ({
  to,
  children,
  className,
  external,
  isActive: isMenuActive,
  ...props
}: {
  children: ReactElement
  to?: string
  className?: string
  external?: boolean
  onClick?: (event: MouseEvent<HTMLElement>) => void
  isActive?: boolean
}) =>
  !!to ? (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames((isActive || isMenuActive) && 'bg-white/10', className)
      }
      target={external ? '_blank' : '_self'}
      {...props}
    >
      {children}
    </NavLink>
  ) : (
    <span className={className} {...props}>
      {children}
    </span>
  )

export const NavigationGroup = ({
  group,
  onGroupItemClick,
  className,
}: {
  group: NavigationGroupInterface
  onGroupItemClick?: Function
  className?: string
}) => {
  const handleOnGroupItemClick = (
    event: MouseEvent<HTMLElement>,
    item: NavigationItemInterface
  ) => {
    item.onClick && item.onClick(event)

    onGroupItemClick?.()
  }

  return (
    <div className={classNames('p-2 flex items-center', className)}>
      {group.items.map((item, index) => {
        const groupItem = (
          <NavigationGroupItem
            key={index}
            to={item.to}
            className={classNames(
              'sm:py-1 px-2 flex items-center flex-row mx-auto  rounded transition-colors cursor-pointer space-x-1',
              group.className,
              item.isHidden && 'hidden',
              item.highlight
                ? 'bg-brand-2 text-white !opacity-100 hover:bg-opacity-50'
                : 'text-gray-300 hover:text-gray-700 hover:bg-gray-200'
            )}
            external={item.external}
            onClick={(event: MouseEvent<HTMLElement>) =>
              handleOnGroupItemClick(event, item)
            }
          >
            <>
              {!!item.icon && <div className="h-5 w-5">{item.icon}</div>}

              {!!item.title && (
                <span className="inline-flex">{item.title}</span>
              )}

              {!!item.external && (
                <div className="opacity-50 h-4 w-4">
                  <ArrowTopRightOnSquare className="h-full w-full" />
                </div>
              )}
            </>
          </NavigationGroupItem>
        )

        if (item.content) {
          return (
            <DropdownContent key={index} content={item.content}>
              {groupItem}
            </DropdownContent>
          )
        }

        return item.links ? (
          <DropdownMenu
            width={180}
            id={item.id}
            links={item.links}
            key={index}
            alignment={item.alignment}
          >
            {groupItem}
          </DropdownMenu>
        ) : (
          groupItem
        )
      })}
    </div>
  )
}
