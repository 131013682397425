import { ContractTerm } from '@/pages/ServiceWizard/ServiceWizard.types'
import { contractTerms } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import { classNames } from '@/lib/misc/Utils'
import { m } from 'framer-motion'
import { useMemo } from 'react'

interface WizardContractTermProps {
  inline?: boolean
  contract?: number
  setContractTerm: (term: ContractTerm) => void
  minContractTerm?: number | undefined
  description?: string
  title?: string
  showTitle?: boolean
  setDefaultValue?: boolean
  showLessThanMinimumContractTerm?: boolean
}

const WizardContractTerm = ({
  inline = true,
  contract,
  setContractTerm,
  minContractTerm,
  description,
  title,
  showTitle = true,
  setDefaultValue = true,
  showLessThanMinimumContractTerm = false,
}: WizardContractTermProps) => {
  const filteredContractTerms = useMemo(
    () =>
      contractTerms.filter(
        (term) =>
          !minContractTerm ||
          showLessThanMinimumContractTerm ||
          term.period >= minContractTerm
      ),
    [showLessThanMinimumContractTerm, minContractTerm]
  )

  const contractPeriod = useMemo(
    () =>
      setDefaultValue ? contract || filteredContractTerms[0].period : contract,
    [contract, filteredContractTerms, setDefaultValue]
  )

  const containerVariants = {
    hidden: {},
    visible: { transition: { staggerChildren: 0.1, delayChildren: 0.1 } },
  }

  const onContractTermClick = (term: ContractTerm) => {
    if (minContractTerm && minContractTerm > term.period) {
      return
    }
    setContractTerm(term)
  }

  return (
    <div
      data-cy="ddos-contract-term"
      className={classNames(
        'flex justify-between flex-wrap',
        !inline && 'flex-col'
      )}
    >
      {showTitle && (
        <h3 className="text-brand-4 text-2xl leading-[120%] tracking-[-0.24px] mb-1 md:leading-[normal] md:tracking-[-0.48px]">
          {title ?? 'Select contract term'}
        </h3>
      )}

      {description && <p className="text-neutral-40">{description}</p>}

      <m.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className={classNames(
          'gap-x-3 p-1 border-1 rounded-lg',
          inline
            ? 'flex w-fit border-accent-30'
            : `grid grid-cols-${filteredContractTerms.length} w-full bg-white border-neutral-20 px-1`,
          showTitle && 'mt-4'
        )}
      >
        {filteredContractTerms.map((term) => (
          <div
            key={term.label_short}
            data-cy={`wizard-contract-term-${term.label_short}`}
            onClick={() => onContractTermClick(term)}
            className={classNames(
              'rounded-lg py-1 px-2 text-center font-normal tracking-tightest uppercase transition-opacity duration-300',
              inline ? 'bg-accent-60 text-xs' : 'bg-accent-40 py-3 text-sm',
              contractPeriod === term.period
                ? 'text-white'
                : 'text-accent-60 !bg-transparent',
              minContractTerm && term.period < minContractTerm
                ? 'opacity-50 cursor-not-allowed'
                : 'cursor-pointer hover:opacity-75'
            )}
          >
            {contractPeriod === term.period || !inline
              ? term.label
              : term.label_short}
          </div>
        ))}
      </m.div>
    </div>
  )
}
export default WizardContractTerm
